import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IHomePage_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IHomePage_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  AktifSirket_N: any[];
  GetFasoncuyaGonderilenliste: any[];

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class HomePage_ScreenBase extends React.PureComponent<IHomePage_ScreenProps, any> {
  homepage_25957_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "86c198d2-2610-49c1-b2d1-b0fad74723cd",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "c7b2ae2c-374d-4c0c-b5bd-8b2f1bb37d12",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 625866, PropertyName: "value", Value: "Yeni Gelen İşler" },
        { Id: 724181, PropertyName: "value", Value: "10" },
        { Id: 378348, PropertyName: "value", Value: "Bekleyen İşlerim" },
        { Id: 462340, PropertyName: "value", Value: "25" },
        { Id: 90595, PropertyName: "value", Value: "Tamamlanan İşlerim" },
        { Id: 26789, PropertyName: "value", Value: "100" },
        { Id: 758461, PropertyName: "value", Value: "Red/Fire" },
        { Id: 868529, PropertyName: "value", Value: "2" },
        { Id: 25957, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 556385, PropertyName: "title", Value: "Sevk İş Emri No" },
        { Id: 204768, PropertyName: "value", Value: "[datafield:isemrino]" },
        { Id: 949097, PropertyName: "title", Value: "Stok Kodu" },
        { Id: 706301, PropertyName: "value", Value: "[datafield:urun_kodu]" },
        { Id: 396587, PropertyName: "title", Value: "Stok Adı" },
        { Id: 712622, PropertyName: "value", Value: "[datafield:urun_adi]" },
        { Id: 444508, PropertyName: "title", Value: "Fatura Kodu" },
        { Id: 187504, PropertyName: "value", Value: "[datafield:kt_fason_kodu]" },
        { Id: 362519, PropertyName: "title", Value: "Fatura Tanımı" },
        { Id: 990213, PropertyName: "value", Value: "[datafield:stok_adi]" },
        { Id: 272329, PropertyName: "title", Value: "Miktar/Adet" },
        { Id: 438665, PropertyName: "value", Value: "[datafield:hesaplananmiktar]" },
        { Id: 470238, PropertyName: "title", Value: "Miktar/Kg" },
        { Id: 541313, PropertyName: "value", Value: "[datafield:agirlik]" },
        { Id: 243077, PropertyName: "title", Value: "Birim Fiyat" },
        { Id: 959065, PropertyName: "value", Value: "[datafield:birimfiyat]" },
        { Id: 682843, PropertyName: "title", Value: "Toplam Fiyat" },
        { Id: 745743, PropertyName: "value", Value: "[datafield:tutar]" },
        { Id: 224475, PropertyName: "title", Value: "Üretim İş Emri No" },
        { Id: 309995, PropertyName: "value", Value: "[datafield:uretim_isemrino]" },
        { Id: 751003, PropertyName: "title", Value: "Yapılan Miktar/Adet" },
        { Id: 350228, PropertyName: "value", Value: "[datafield:yapilan_adet]" },
        { Id: 78527, PropertyName: "title", Value: "Yapılan Miktar/Kg" },
        { Id: 54432, PropertyName: "value", Value: "[datafield:yapilan_kg]" },
        { Id: 877597, PropertyName: "title", Value: "Kalan Miktar" },
        { Id: 578803, PropertyName: "value", Value: "[datafield:kalan_adet]" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.homepage_25957_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      AktifSirket_N: [],
      GetFasoncuyaGonderilenliste: [],

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("homepage", "fasontakiplayout_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.HomePagePageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("homepage", "fasontakiplayout_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("homepage", "fasontakiplayout_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.HomePagePageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.HomePagePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };

  HomePagePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      carikod_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "HomePage/HomePagePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AktifSirket_N = result?.data.aktifSirket_N;
    stateVars.GetFasoncuyaGonderilenliste = result?.data.getFasoncuyaGonderilenliste;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.HomePagePageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  HomePagePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_25957 = this.state.GetFasoncuyaGonderilenliste;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
