import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITedarikcitanimlama_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITedarikcitanimlama_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  AktifSirket_N: any[];
  CariListesi: any[];
  DepoListesi: any[];
  KullaniciListesi: any[];
  TedarikciListesiCount: any[];
  SifreUret_N: any[];
  KullaniciKayit: number;
  TriggerEvent: string;
  SetValueOf: any;
  Kullanici_Sil: number;

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class Tedarikcitanimlama_ScreenBase extends React.PureComponent<ITedarikcitanimlama_ScreenProps, any> {
  tedarikcitanimlama_287275_value_kuikaSelectBoxRef: React.RefObject<any>;
  tedarikcitanimlama_840596_value_kuikaSelectBoxRef: React.RefObject<any>;
  tedarikcitanimlama_273923_value_kuikaTableRef: React.RefObject<any>;
  tedarikcitanimlama_110192_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "86c198d2-2610-49c1-b2d1-b0fad74723cd",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "93a23184-55b8-4300-bfb9-6306b1735dac",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 521749, PropertyName: "value", Value: "Tedarikçi" },
        { Id: 287275, PropertyName: "placeholder", Value: "Tedarikçi Seçiniz.." },
        { Id: 358531, PropertyName: "value", Value: "Depo Kodu" },
        { Id: 840596, PropertyName: "placeholder", Value: "Depo Seçiniz.." },
        { Id: 968441, PropertyName: "value", Value: "Portal Şifre" },
        { Id: 217459, PropertyName: "placeholder", Value: "***********" },
        { Id: 189758, PropertyName: "label", Value: "Şifre Üret" },
        { Id: 543281, PropertyName: "label", Value: "Kaydet" },
        { Id: 273923, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 129394, PropertyName: "title", Value: "Cari Kodu" },
        { Id: 319753, PropertyName: "value", Value: "[datafield:cari_kodu]" },
        { Id: 984847, PropertyName: "title", Value: "Cari Adı" },
        { Id: 80132, PropertyName: "value", Value: "[datafield:cari_isim]" },
        { Id: 948000, PropertyName: "title", Value: "Depo Kodu" },
        { Id: 99016, PropertyName: "value", Value: "[datafield:depo_kodu]" },
        { Id: 264485, PropertyName: "title", Value: "Şifre" },
        { Id: 738988, PropertyName: "value", Value: "[datafield:sifre]" },
        { Id: 300429, PropertyName: "title", Value: "İşlemler" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);
    this.tedarikcitanimlama_287275_value_kuikaSelectBoxRef = React.createRef();
    this.tedarikcitanimlama_840596_value_kuikaSelectBoxRef = React.createRef();
    this.tedarikcitanimlama_273923_value_kuikaTableRef = React.createRef();
    this.tedarikcitanimlama_110192_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      AktifSirket_N: [],
      CariListesi: [],
      DepoListesi: [],
      KullaniciListesi: [],
      TedarikciListesiCount: [],
      SifreUret_N: [],
      KullaniciKayit: 0,
      TriggerEvent: "",
      SetValueOf: "",
      Kullanici_Sil: 0,

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tedarikcitanimlama", "fasontakiplayout_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.TedarikcitanimlamaPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tedarikcitanimlama", "fasontakiplayout_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tedarikcitanimlama", "fasontakiplayout_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TedarikcitanimlamaPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.TedarikcitanimlamaPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };

  TedarikcitanimlamaPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Kullanici_Tipi_3: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      currentpage_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcitanimlama_110192_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcitanimlama_110192_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      tip_4: ReactSystemFunctions.convertToTypeByName(false, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Tedarikcitanimlama/TedarikcitanimlamaPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AktifSirket_N = result?.data.aktifSirket_N;
    stateVars.CariListesi = result?.data.cariListesi;

    formVars.tedarikcitanimlama_287275_options = stateVars.CariListesi;
    stateVars.DepoListesi = result?.data.depoListesi;

    formVars.tedarikcitanimlama_840596_options = stateVars.DepoListesi;
    stateVars.KullaniciListesi = result?.data.kullaniciListesi;

    stateVars.TedarikciListesiCount = result?.data.tedarikciListesiCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TedarikcitanimlamaPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikcitanimlamaPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_287275 = this.state.CariListesi;

    stateVars.dataSource_840596 = this.state.DepoListesi;

    stateVars.dataSource_273923 = this.state.KullaniciListesi;
    formVars.tedarikcitanimlama_110192_total = ReactSystemFunctions.value(
      this,
      this.state.TedarikciListesiCount?.length > 0 ? this.state.TedarikciListesiCount[0]?.toplamKayitSayisi : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TedarikcitanimlamaComponent_189758_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Tedarikcitanimlama/TedarikcitanimlamaComponent_189758_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SifreUret_N = result?.data.sifreUret_N;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TedarikcitanimlamaComponent_189758_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikcitanimlamaComponent_189758_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.tedarikcitanimlama_217459_value = ReactSystemFunctions.toString(
      this,
      this.state.SifreUret_N?.length > 0 ? this.state.SifreUret_N[0]?.simplePassword : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikcitanimlamaComponent_543281_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcitanimlama_287275_value", "value", "CariListesi", "cari_kod", "")
        ),
        null
      ),
      message: "Tedarikçi boş Geçilemez !",
      formName: "tedarikcitanimlama_287275_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcitanimlama_287275_value", "value", "CariListesi", "cari_kod", "")
        ),
        null
      ),
      message: "Boş Geçilemez !",
      formName: "tedarikcitanimlama_287275_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcitanimlama_840596_value", "value", "DepoListesi", "depo_kodu", "")
        ),
        null
      ),
      message: "Depo Kodu Boş Geçilemez !",
      formName: "tedarikcitanimlama_840596_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcitanimlama_840596_value", "value", "DepoListesi", "depo_kodu", "")
        ),
        null
      ),
      message: "Boş Geçilemez !",
      formName: "tedarikcitanimlama_840596_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcitanimlama_217459_value", "value", "", "", "")
        ),
        null
      ),
      message: "Lütfen Şifre Belirtiniz.",
      formName: "tedarikcitanimlama_217459_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CARI_KODU_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikcitanimlama_287275_value",
            "value",
            "CariListesi",
            "cari_kod",
            "cari_kod"
          )
        ),
        "string"
      ),
      DEPO_KODU_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikcitanimlama_840596_value",
            "value",
            "DepoListesi",
            "depo_kodu",
            "depo_kodu"
          )
        ),
        "string"
      ),
      SIFRE_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcitanimlama_217459_value", "value", "", "", "")
        ),
        "string"
      ),
      TIP_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      Kullanici_Kodu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikcitanimlama_287275_value",
            "value",
            "CariListesi",
            "cari_kod",
            "cari_kod"
          )
        ),
        "string"
      ),
      CARI_ISIM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikcitanimlama_287275_value",
            "value",
            "CariListesi",
            "cari_kod",
            "cari_isim"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Tedarikcitanimlama/TedarikcitanimlamaComponent_543281_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciKayit = result?.data.kullaniciKayit;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TedarikcitanimlamaComponent_543281_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikcitanimlamaComponent_543281_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    await this.TedarikcitanimlamaPageInit();

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tedarikcitanimlama_287275_value",
      null,
      null,
      "cari_kod"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tedarikcitanimlama_840596_value",
      null,
      null,
      "depo_kodu"
    );
    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "tedarikcitanimlama_217459_value", null, null);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikcitanimlamaComponent_411242_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CARI_KODU_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "tedarikcitanimlama_273923_value", "cARI_KODU"),
        "string"
      ),
      TIP_0: ReactSystemFunctions.convertToTypeByName(false, "boolean")
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "91f117c4_cb94_e3e9_7ba2_7ce18ffdd569_confirmation",
        this.defaultML,
        "Seçilen Kayıt Silinecektir ?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "Tedarikcitanimlama/TedarikcitanimlamaComponent_411242_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.Kullanici_Sil = result?.data.kullanici_Sil;
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.TedarikcitanimlamaComponent_411242_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikcitanimlamaComponent_411242_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    await this.TedarikcitanimlamaPageInit();

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikcitanimlamaComponent_110192_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Kullanici_Tipi_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      currentpage_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcitanimlama_110192_value", "current", "", "", "")
        ),
        "number"
      ),
      pagesize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcitanimlama_110192_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Tedarikcitanimlama/TedarikcitanimlamaComponent_110192_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciListesi = result?.data.kullaniciListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.TedarikcitanimlamaComponent_110192_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikcitanimlamaComponent_110192_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    await this.TedarikcitanimlamaPageInit();

    stateVars.dataSource_273923 = this.state.KullaniciListesi;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
