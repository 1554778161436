import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import { Modal } from "antd";
import { PureComponent, ReactNode } from "react";
import { isMobile } from "react-device-detect";
import { Camera, FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import { connect } from "react-redux";
import { Route as DomRoute, Route } from "react-router-dom";
import { PaymentElementStripe } from "../components/web-components";
import {
  hideIyzicoDrawerAction,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../redux/photo-take/actions";
import { AppState } from "../redux/root-reducer";
import { KContext } from "../shared/hoc/with-context";

interface RouteProps {
  component?: any;
  exact?: boolean;
  path: string;
  photoTakeVisible?: any;
  hidePhotoTake?: any;
  stripeDrawerVisible?: any;
  hideStripeDrawer?: any;
  stripeData?: any;
  stripeCallBack?: any;
  screen?: any;
  futureUse?: any;
}

interface RouteState {
  context: KContext | undefined;
}

class Route extends PureComponent<RouteProps, RouteState> {
  constructor(props: RouteProps) {
    super(props);
    this.state = { context: undefined };
  }

  handleClosePhoto = () => {
    const imgNode: HTMLImageElement = document
      .getElementsByClassName("react-html5-camera-photo")[0]
      .getElementsByTagName("img")[0];
    imgNode.src = "";
    this.props.hidePhotoTake();
  };

  renderStripeDrawer = () => {
    if (this.props.stripeDrawerVisible) {
      return (
        <Modal
          destroyOnClose
          onCancel={() => {
            this.props.screen.setState(
              { OpenStripePaymentDrawer: { isSuccess: false, errorMessage: "Action aborted by the user" } },
              () => {
                this.props.hideStripeDrawer();
                this.props.stripeCallBack();
              }
            );
          }}
          footer={null}
          open={this.props.stripeDrawerVisible}
        >
          <PaymentElementStripe
            stripeAction={this.props.stripeData}
            callback={this.props.stripeCallBack}
            screen={this.props.screen}
            futureUse={this.props.futureUse}
          />
        </Modal>
      );
    }
    return <></>;
  };

  renderPhotoTake = () => {
    if (!this.props.photoTakeVisible) {
      return <></>;
    }
    if (isMobile) {
      return (
        <>
          <CloseOutlined
            onClick={this.handleClosePhoto}
            style={{ position: "absolute", top: 60, fontSize: 40, zIndex: 99999, left: `calc(90vw)` }}
          />
          <Camera
            imageType={IMAGE_TYPES.JPG}
            isFullscreen
            isImageMirror={false}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
          />
        </>
      );
    }
    return (
      <Modal
        destroyOnClose
        closable
        onCancel={this.handleClosePhoto}
        width="fit-content"
        footer={null}
        open={this.props.photoTakeVisible}
      >
        <Camera imageType={IMAGE_TYPES.JPG} isImageMirror={false} idealFacingMode={FACING_MODES.ENVIRONMENT} />
      </Modal>
    );
  };

  render(): ReactNode {
    return (
      <>
        {this.renderPhotoTake()}
        {this.renderStripeDrawer()}
        <DomRoute
          exact={this.props.exact}
          path={this.props.path}
          render={(props) => {
            return <this.props.component {...props} context={this.state.context} />;
          }}
        />
      </>
    );
  }
}
const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    stripeDrawerVisible: state.stripeDrawerReducer.visible,
    stripeData: state.stripeDrawerReducer.payload,
    stripeCallBack: state.stripeDrawerReducer.callback,
    iyzicoCallBack: state.iyzicoDrawerReducer.callback,
    screen: state.stripeDrawerReducer.screen ?? state.iyzicoDrawerReducer.screen,
    futureUse: state.stripeDrawerReducer.futureUse
  };
};

const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, screen, futureUse) =>
    dispatch(showStripeDrawer(data, callback, screen, futureUse)),
  hideStripeDrawer: (data, callback, screen) => dispatch(hideStripeDrawer(data, callback, screen)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawerAction: (data, callback, that) => dispatch(hideIyzicoDrawerAction(data, callback, that))
});

const route = connect(mapStateToProps, mapDispatchToProps)(Route);
export { route as Route };
