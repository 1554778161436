import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ILogin_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ILogin_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  KullaniciLogin: any[];
  TriggerEvent: string;
  Notify: boolean;
  IfThenElse: boolean;
  Signin: any;
  goToNextScreenAfterLogin: any;
  isComp121174Enabled: "enabled" | "disabled";
}

export class Login_ScreenBase extends React.PureComponent<ILogin_ScreenProps, any> {
  ml = [
    {
      Id: "86c198d2-2610-49c1-b2d1-b0fad74723cd",
      Name: "en_US",
      ShortName: "English",
      IsDefault: true,
      LanguagePhrases: []
    },
    {
      Id: "e7b440a2-8e31-40ee-b009-bee106da908a",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 381410, PropertyName: "value", Value: "Vaden Tedarikçi Portalı" },
        { Id: 279579, PropertyName: "placeholder", Value: "Kullanıcı Adı" },
        { Id: 452791, PropertyName: "placeholder", Value: "Şifre" },
        { Id: 200096, PropertyName: "value", Value: "Şifremi Unuttum" },
        { Id: 869049, PropertyName: "label", Value: "Giriş Yap" },
        { Id: 930406, PropertyName: "value", Value: "SİSYA™" }
      ]
    }
  ];
  defaultML = "en_US";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      KullaniciLogin: [],
      IfThenElse: false,
      Signin: "",
      goToNextScreenAfterLogin: "",
      isComp121174Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("login", "login");
      return;
    }

    if (true) {
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("login", "login");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("641651");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("login", "login");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  LoginComponent_869049_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "login_279579_value", "value", "", "", "")
        ),
        null
      ),
      message: "Kullanıcı Adı Alanı Boş Bırakılamaz",
      formName: "login_279579_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "login_452791_value", "value", "", "", "")
        ),
        null
      ),
      message: "Şifre Alanı Boş Bırakılamaz",
      formName: "login_452791_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      KULLANICI_NO_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "login_279579_value", "value", "", "", "")),
        "string"
      ),
      SIFRE_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "login_452791_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Login/LoginComponent_869049_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciLogin = result?.data.kullaniciLogin;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.LoginComponent_869049_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  LoginComponent_869049_onClick_if1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    if (await this.LoginComponent_121174_onClick()) return true;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  LoginComponent_869049_onClick_else1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "68091838_d468_1468_193d_5557f94a5ebd_notify",
        this.defaultML,
        "Kullanıcı Bilgileri Doğrulanamadı."
      ),
      "warning",
      "bottom-right",
      3
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };

  LoginComponent_869049_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          this.state.KullaniciLogin?.length > 0 ? this.state.KullaniciLogin[0]?.iD : null
        ),
        null
      )
    ) {
      isErrorOccurred = await this.LoginComponent_869049_onClick_if1();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.LoginComponent_869049_onClick_else1();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  LoginComponent_121174_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "login_279579_value", "value", "", "", "")
        ),
        null
      ),
      message: "Kullanıcı Adı Alanı Boş Bırakılamaz",
      formName: "login_279579_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "login_452791_value", "value", "", "", "")
        ),
        null
      ),
      message: "Şifre Alanı Boş Bırakılamaz",
      formName: "login_452791_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Signin = (
      (await ReactSystemFunctions.signin(
        this,
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "login_279579_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "login_452791_value", "value", "", "", ""))
      )) as any
    ).data;
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterLogin(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
