import { Button, Form, Input, Tooltip } from "antd";
import * as React from "react";
import { IResetPasswordDto } from "../../models/dto/resetpassword.dto";
import { UserService } from "../../services/user-service";
import { KNavigator, withHistory } from "../../shared/hoc/with-history";
import { KMainFunctions } from "../../shared/utilty/main-functions";

interface IResetPasswordState {
  resetPasswordErrorMessage: string;
}

interface IResetPasswordProps {
  navigator: KNavigator;
}

class ResetPassword extends React.PureComponent<IResetPasswordProps, IResetPasswordState> {
  private tokenString = "";

  constructor(props) {
    super(props);
    this.state = { resetPasswordErrorMessage: "" };
    this.getToken();
  }

  componentDidMount = () => {
    if (this.tokenString === "") {
      this.setState({ resetPasswordErrorMessage: "Invalid token." });
    }
  };

  getToken = () => {
    if (
      this.props &&
      this.props.navigator.history &&
      this.props.navigator.history.location &&
      this.props.navigator.history.location.search
    ) {
      let idx = this.props.navigator.history.location.search.indexOf("token=");
      const idx2 = this.props.navigator.history.location.search.indexOf("&username");
      if (idx > -1 && idx2 > -1) {
        idx += 6;
        const token = this.props.navigator.history.location.search.substring(idx, idx2);
        this.tokenString = token;
      }
    }
  };

  goToSignIn = () => {
    this.props.navigator.gotoLogin();
  };

  onSubmit = async (data: any) => {
    try {
      if (data.confirmPassword !== data.NewPassword) {
        this.setState({
          resetPasswordErrorMessage: "Your second password doesn't match with the first one."
        });
        return;
      }
      const formData = data as IResetPasswordDto;
      formData.token = this.tokenString;
      UserService.ResetPassword(formData).then(
        (res) => {
          if (res && res.status === 200) {
            this.setState({ resetPasswordErrorMessage: "" });
            this.goToSignIn();
          }
        },
        (error) => {
          if (error.response.data.message) {
            this.setState({
              resetPasswordErrorMessage: error.response.data.message
            });
          }
        }
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
    }
  };

  render() {
    return (
      <>
        <div style={{ width: "400px", margin: "100px auto" }}>
          <div style={{ marginBottom: "50px", marginLeft: "-8px" }}>
            <img className="kuika_loginPageImage" alt="kuika" src="/images/kuikaDesignerLogo.svg"></img>
          </div>
          <h3
            style={{ fontWeight: 600, marginBottom: "30px" }}
            className="kuika_registration_label_leftAlign kuika_registration_rightSide_Label"
          >
            Set New Password
            {UserService.getEmailToResetPassword() !== null && (
              <span
                style={{
                  paddingTop: 6,
                  paddingLeft: 3,
                  fontSize: 14,
                  fontWeight: 500,
                  letterSpacing: 0.41,
                  color: "#707070",
                  display: "block",
                  marginTop: 5
                }}
              >
                for {UserService.getEmailToResetPassword()}
              </span>
            )}
          </h3>
          <Form name="normal_login" className="login-form" onFinish={this.onSubmit}>
            <Form.Item
              name="NewPassword"
              rules={[{ required: true, message: "Please enter your password!" }]}
              style={{ display: "block" }}
            >
              <Input.Password
                className="registraionSvgIconsColor"
                style={{ height: "40px" }}
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ marginRight: "5px" }}
                  >
                    <g fill="none" fillRule="evenodd">
                      <g>
                        <path d="M0 0H24V24H0z" />
                        <g fill="#000" fillRule="nonzero">
                          <path
                            d="M6.4 0C4.64 0 3.2 1.44 3.2 3.2v1.6H1.8C.813 4.8 0 5.613 0 6.6v7.6c0 .987.813 1.8 1.8 1.8H11c.987 0 1.8-.813 1.8-1.8V6.6c0-.987-.813-1.8-1.8-1.8H9.6V3.2C9.6 1.44 8.16 0 6.4 0zm0 1.2c1.112 0 2 .888 2 2v1.6h-4V3.2c0-1.112.888-2 2-2zM1.8 6H11c.339 0 .6.261.6.6v7.6c0 .339-.261.6-.6.6H1.8c-.339 0-.6-.261-.6-.6V6.6c0-.339.261-.6.6-.6zm4.6 3.2c-.663 0-1.2.537-1.2 1.2 0 .663.537 1.2 1.2 1.2.663 0 1.2-.537 1.2-1.2 0-.663-.537-1.2-1.2-1.2z"
                            transform="translate(6 4)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                }
                type="password"
                placeholder=" Password"
              />
            </Form.Item>
            <Form.Item name="confirmPassword" rules={[{ required: true, message: "Please enter your password!" }]}>
              <Input.Password
                className="registraionSvgIconsColor"
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{ marginRight: "5px", marginTop: "5px" }}
                  >
                    <g fill="none" fillRule="evenodd">
                      <g>
                        <path d="M0 0H24V24H0z" />
                        <g fill="#000" fillRule="nonzero">
                          <path
                            d="M6.4 0C4.64 0 3.2 1.44 3.2 3.2v1.6H1.8C.813 4.8 0 5.613 0 6.6v7.6c0 .987.813 1.8 1.8 1.8H11c.987 0 1.8-.813 1.8-1.8V6.6c0-.987-.813-1.8-1.8-1.8H9.6V3.2C9.6 1.44 8.16 0 6.4 0zm0 1.2c1.112 0 2 .888 2 2v1.6h-4V3.2c0-1.112.888-2 2-2zM1.8 6H11c.339 0 .6.261.6.6v7.6c0 .339-.261.6-.6.6H1.8c-.339 0-.6-.261-.6-.6V6.6c0-.339.261-.6.6-.6zm4.6 3.2c-.663 0-1.2.537-1.2 1.2 0 .663.537 1.2 1.2 1.2.663 0 1.2-.537 1.2-1.2 0-.663-.537-1.2-1.2-1.2z"
                            transform="translate(6 4)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                }
                type="password"
                placeholder=" Confirm password"
              />
            </Form.Item>
            <Form.Item style={{ paddingTop: 12, display: "block" }}>
              <Button
                style={{ width: "100%", border: "none" }}
                htmlType="submit"
                size="large"
                className="login-form-button k_primary"
              >
                Set new password
              </Button>
            </Form.Item>
          </Form>
          <div style={{ fontWeight: 500, marginTop: 24, display: "block" }} className="k_Bold-Primary-Center">
            <span className="kuika_registration_label_rightAlign" onClick={() => this.goToSignIn()}>
              Sign in
            </span>
          </div>
          <a
            href="https://kuika.com/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
            className="kuika_termsOfService k_Medium-Primary-Left"
          >
            Terms of services
          </a>
          {this.state.resetPasswordErrorMessage !== "" && (
            <Tooltip placement="top" title={this.state.resetPasswordErrorMessage}>
              <div className="kuika_registration_error">{this.state.resetPasswordErrorMessage}</div>
            </Tooltip>
          )}
        </div>
      </>
    );
  }
}

const resetPassword = withHistory(ResetPassword);
export { resetPassword as ResetPassword };
