import { ConfigProvider } from "antd";
import * as React from "react";
import { memo } from "react";
import PWAPrompt from "react-ios-pwa-prompt";
import ReactNotification from "react-notifications-component";
import { Provider } from "react-redux";
import "../auth/interceptors";
import { IAppMode, IAuthType } from "../bootstrapper";
import configureStore from "../redux/root-reducer";
import { IRouterType, Router } from "../routes/router";
import { ReactSystemFunctions } from "../shared/utilty/react-system-functions";

const store = configureStore();

interface IAppProps {
  routerType?: IRouterType;
  authType?: IAuthType;
  external_access_token?: string;
  external_refresh_token?: string;
  external_user?: string;
  appMode?: IAppMode;
}

const KuikaAuthApp = (props: IAppProps) => {
  let { authType } = props;
  if (authType == undefined) {
    authType = "Kuika";
  }
  return (
    <>
      {/* <React.StrictMode> */}
      <React.Suspense fallback="Loading...">
        <Provider store={store}>
          <ReactNotification />
          <ConfigProvider locale={ReactSystemFunctions.getLocale()}>
            <PWAPrompt
              copyBody="This website has app functionality. Add it to your home screen to use it in fullscreen"
              copyClosePrompt="Close"
              permanentlyHideOnDismiss
            />
            <Router
              authType={authType}
              routerType={props.routerType}
              appMode={props.appMode}
              external_user={props.external_user}
              external_access_token={props.external_access_token}
              external_refresh_token={props.external_refresh_token}
            />
          </ConfigProvider>
        </Provider>
      </React.Suspense>
      {/* </React.StrictMode> */}
    </>
  );
};

export default memo(KuikaAuthApp);
